// .patient-persist-insurance-card {
//   border-radius: 6px;
// }

// .center-icon {
//   margin: auto;
// }

// .patient-persist-communication-opt-out-icon {
//   margin-right: 5px;
// }

// .patient-persist-communication-opt-out-font {
//   color: #ca2a29;
// }

.patient-persist-healthcard-carousel {
  > * {
    text-align: center;

    & .ant-modal-header {
      text-align: start;
    }

    & .ant-modal-footer {
      text-align: start;
    }

    & .carousel-footer {
      width: 120px;
      height: 80px;
      margin: 5px;
      cursor: pointer;
    }
  }
  & .slick-slide {
    justify-content: center;
  }
  & .slick-next {
    color: black;
    font-size: 20px;
    &:hover {
      color: black;
    }
    &:focus {
      color: black;
    }
  }
  & .slick-prev {
    color: black;
    font-size: 20px;
    &:hover {
      color: black;
    }
    &:focus {
      color: black;
    }
  }

  .preview-images {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
